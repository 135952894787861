<template>
  <div>
    <!-- Encabezado y Botones -->
    <b-row class="mb-2">
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1>{{ $t('supplies') }}</h1>
        <b-dropdown
          v-if="isSmallScreen"
          variant="outline-primary"
          right
          :text="$t('add', { type: '' })"
        >
          <b-dropdown-item
            class="mr-1"
            variant="primary"
            @click="$bvModal.show('newMaterial')"
          >
            {{ $t('new', { type: $t('material') }) }}
          </b-dropdown-item>
          <b-dropdown-item
            class="mr-1"
            :to="{
              name: 'addGroupedExpenses',
              params: { projectId: $route.params.projectId },
            }"
            variant="primary"
          >
            {{ $t('add', { type: $t('supply') }) }}
          </b-dropdown-item>
          <b-dropdown-item
            variant="primary"
            @click="downloadGroupedExpenseReport($route.params.projectId)"
          >
            {{ $t('downloadDocument') }}
          </b-dropdown-item>
        </b-dropdown>
        <div v-else>
          <b-button
            class="mr-1"
            variant="primary"
            @click="$bvModal.show('newMaterial')"
          >
            {{ $t('new', { type: $t('material') }) }}
          </b-button>
          <b-button
            class="mr-1"
            :to="{
              name: 'addGroupedExpenses',
              params: { projectId: $route.params.projectId },
            }"
            variant="primary"
          >
            {{ $t('add', { type: $t('supply') }) }}
          </b-button>
          <b-button
            variant="primary"
            @click="downloadGroupedExpenseReport($route.params.projectId)"
          >
            {{ $t('downloadDocument') }}
          </b-button>
        </div>
      </b-col>
    </b-row>

    <!-- Tabla de Suministros -->
    <div class="table-responsive">
      <b-table striped hover :items="filteredGroupedExpenses" :fields="fields">
        <template #cell(no)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(unit_type)="row">
          {{ $t(row.item.unit_type) }}
        </template>
        <template #cell(volume_purchased)="row">
          <!-- Resaltar en rojo si purchased_quantity > top_quantity -->
          <span :class="{ 'text-danger': row.item.purchased_quantity > row.item.top_quantity }">
            {{ row.item.purchased_quantity }}
          </span>
        </template>
        <template #cell(volume_top)="row">
          {{ row.item.top_quantity }}
        </template>
        <template #cell(spent_amount)="row">
          <span :class="{ 'text-danger': row.item.purchased_amount > row.item.top_amount }">
            {{ row.item.purchased_amount }}
          </span>
        </template>
        <template #cell(top_amount)="row">
          {{ row.item.top_amount }}
        </template>
        <template #cell(average_unit_price)="row">
          {{ calculateAverageUnitPrice(row.item) }}
        </template>
        <template #cell(explosion_unit_price)="row">
          {{ calculateExplosionUnitPrice(row.item) }}
        </template>
        <template #cell(actions)="row">
          <b-button
            size="sm"
            variant="outline-primary"
            @click="openEditModal(row.item)"
          >
            ✏️
          </b-button>
          <b-button
            size="sm"
            variant="outline-danger"
            @click="openDeleteModal(row.item)"
          >
            ❌
          </b-button>
        </template>
      </b-table>
    </div>
    <!-- Modal Editar -->
    <b-modal id="edit-modal" @ok="editExpense">
      <b-row>
        <b-col>
          <b-form-group
            class="mb-0"
            :label="$t('topQuantity')"
          >
            <b-form-input
              v-model="expenseToEdit.top_quantity"
              type="number"
              min="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-0"
            :label="$t('topAmount')"
          >
            <b-form-input
              v-model="expenseToEdit.top_amount"
              type="number"
              min="1"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Modal Confirmar Eliminación -->
    <confirmation-modal
      id="delete-modal"
      variant="danger"
      @confirm="deleteExpense"
    >
      <template #content>
        {{ $t('deleteSupplyConfirmation') }}
      </template>
    </confirmation-modal>

    <!-- Modal para agregar nuevo material -->
    <b-modal
      id="newMaterial"
      @ok="saveExpenses"
      @cancel="newMaterial = {}"
    >
      <b-row>
        <b-col sm="12">
          <b-form-group
            class="mb-0"
            :label="$t('name')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.name"
              min="1"
              class="qty-field"
              autocomplete="off"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            class="mb-0"
            :label="$t('brand')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.brand"
              min="1"
              class="qty-field"
              autocomplete="off"
              type="text"
            />
          </b-form-group>
        </b-col>
        <!-- Otros campos -->
        <b-col sm="12">
          <b-form-group
            class="mb-0"
            :label="$t('description')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.description"
              min="1"
              class="qty-field"
              autocomplete="off"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            class="mb-0"
            :label="$t('unit')"
            label-for="input-sm"
          >
            <b-form-select
              v-model="newMaterial.unit_type"
              :options="unitTypeOptions"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            class="mb-0"
            :label="$t('category')"
            label-for="input-sm"
          >
            <b-form-select
              v-model="newMaterial.category"
              text-field="name"
              value-field="id"
              :options="categories"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-0"
            :label="$t('topQuantity')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.top_quantity"
              type="number"
              min="1"
              class="qty-field"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-0"
            :label="$t('topAmount')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.top_amount"
              type="number"
              min="1"
              class="qty-field"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      expenseToEdit: {}, // Contiene los datos del suministro que se está editando
      expenseToDelete: {}, // Contiene los datos del suministro que se va a eliminar
      newMaterial: {},
      materialExpenseType: '',
      unitTypeOptions: [
        {
          value: 'meter',
          text: this.$t('meter'),
        },
        {
          value: 'square_meters',
          text: this.$t('squareMeters'),
        },
        {
          value: 'cubic_meters',
          text: this.$t('cubicMeters'),
        },
        {
          value: 'piece',
          text: this.$t('piece'),
        },
        {
          value: 'kilogram',
          text: this.$t('kilogram'),
        },
        {
          value: 'ton',
          text: this.$t('ton'),
        },
        {
          value: 'bundle',
          text: this.$t('bundle'),
        },
        {
          value: 'box',
          text: this.$t('box'),
        },
        {
          value: 'trip',
          text: this.$t('trip'),
        },
      ],
      fields: [
        { key: 'no', label: 'No.' },
        { key: 'name', label: this.$t('name') },
        { key: 'unit_type', label: this.$t('unit') },
        { key: 'volume_purchased', label: this.$t('accumulatedQuantity') },
        { key: 'volume_top', label: this.$t('topQuantity') },
        { key: 'spent_amount', label: this.$t('pruchasedAmount') },
        { key: 'top_amount', label: this.$t('topAmount') },
        { key: 'average_unit_price', label: this.$t('puAverage') },
        { key: 'explosion_unit_price', label: this.$t('puGropued') },
        { key: 'actions', label: this.$t('actions') },
      ],
    }
  },
  computed: {
    ...mapGetters('groupedExpenses', ['groupedExpenses']),
    filteredGroupedExpenses() {
      return this.groupedExpenses.filter(
        expense => expense.expense_type_catalog.name === 'Material',
      )
    },
    ...mapGetters('app', ['isSmallScreen']),
    ...mapGetters('categories', ['categories']),
  },

  async mounted() {
    this.fetchCategories()
    await this.fetchGroupedExpenses({ projectId: this.$route.params.projectId })
    const res = await this.fetchExpensesTypeCatalogs(this.$route.params.projectId)
    const expensesTypes = res.data
    this.expenseType = expensesTypes.find(
      expense => expense.name === 'Material',
    ).id
  },
  methods: {
    ...mapActions('groupedExpenses', [
      'deleteGropedExpense',
      'editGroupedExpense',
      'fetchGroupedExpenses',
      'downloadGroupedExpenseReport',
      'addGroupedExpenses',
      'fetchExpensesTypeCatalogs',
    ]),
    ...mapActions('categories', ['fetchCategories']),
    calculateAverageUnitPrice(item) {
      return item.purchased_quantity > 0
        ? (item.purchased_amount / item.purchased_quantity).toFixed(2)
        : '0.00'
    },
    openEditModal(expense) {
      this.expenseToEdit = { ...expense } // Clonar el objeto para editarlo
      this.$bvModal.show('edit-modal')
    },
    editExpense() {
      this.editGroupedExpense(this.expenseToEdit).then(() => {
        this.fetchGroupedExpenses({ projectId: this.$route.params.projectId })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('dataSaved'),
            icon: 'MinimizeIcon',
            variant: 'success',
          },
        })
        this.$bvModal.hide('edit-modal')
      })
    },
    openDeleteModal(expense) {
      this.expenseToDelete = expense
      this.$bvModal.show('delete-modal')
    },
    deleteExpense() {
      this.deleteGropedExpense(this.expenseToDelete.id).then(() => {
        this.fetchGroupedExpenses({ projectId: this.$route.params.projectId })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('expenseDeleted'),
            icon: 'TrashIcon',
            variant: 'danger',
          },
        })
        this.$bvModal.hide('delete-modal')
      })
    },
    calculateExplosionUnitPrice(item) {
      return item.explosion_unit_price || 'N/A'
    },
    saveExpenses() {
      const expense = {
        project_id: this.$route.params.projectId,
        grouped_expense_records: [],
      }
      expense.grouped_expense_records.push(this.newMaterial)
      expense.grouped_expense_records = expense.grouped_expense_records.map(
        item => ({
          expense_type_catalog_id: this.expenseType,
          name: item.name,
          top_quantity: item.top_quantity || 0,
          top_amount: item.top_amount,
          unit_type: item.unit_type,
          brand: item.brand,
          description: item.description,
          sku: item.sku,
        }),
      )
      this.addGroupedExpenses(expense).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('dataSaved'),
            icon: 'MinimizeIcon',
            variant: 'success',
          },
        })
        this.fetchGroupedExpenses({ projectId: this.$route.params.projectId })
        this.$bvModal.hide('newMaterial')
        this.newMaterial = {}
      })
    },
  },
}
</script>

<style scoped>
.text-danger {
  font-weight: bold;
  color: red;
}

.table-responsive {
  overflow-x: auto;
}
</style>
